import Vue from 'vue'
import VueRouter from 'vue-router'
import login from "@/components/common/login.vue";

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        name: 'login',
        component: login,
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/IndexView.vue')
    },
    // {
    //     path: '/user',
    //     name: 'user',
    //     component: () => import('../views/UserView.vue')
    // },
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/ProductView.vue'),
    },
    {
        path: '/product/productId/:productId',
        name: 'productDetails',
        component: () => import("../views/ProductDetailsView.vue")
    },
    {
        path: '/example',
        name: 'example',
        component: () => import('../views/ExampleView.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/NewsView.vue')
    },
    {
        path: '/news/newsId/:newsId',
        name: 'newsDetails',
        component: () => import('../views/NewsDetailsView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/evaluate',
        name: 'evaluate',
        component: () => import('../views/cjevaluateView.vue')
    },
    {
        path: '/wlxgyq',
        name: 'wlxgyq',
        component: () => import('../views/WlxgyqView.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

export default router
