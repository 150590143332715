<template>
  <div class="site-wrapper site-page--login">
    <div class="site-content__wrapper">
      <div class="login-main">
        <div style="margin: auto;padding: 10px;position: relative;" >
          <img style="width: 100%" src="../../assets/images/login_bg1.png">
          <div class="login-form">
            <form class="am-form am-form-horizontal">
              <div class="am-form-group" style="margin-top: 82px;margin-left: 175px;">
                <div class="am-u-sm-10">
                  <input type="text" v-model="username" style="background: rgb(11,126,232);color: #ffffff;height: 60px" id="doc-ipt-3" placeholder="输入你的用户名">
                </div>
              </div>

              <div class="am-form-group" style="margin-top: 91px;margin-left: 175px;">
                <div class="am-u-sm-10">
                  <input type="password" v-model="password" style="background: rgb(11,126,232);color: #ffffff;height: 60px" id="doc-ipt-pwd-2" placeholder="输入你的密码">
                </div>
              </div>

              <!--            <div class="am-form-group">-->
              <!--              <div class="am-u-sm-offset-2 am-u-sm-10">-->
              <!--                <div class="checkbox">-->
              <!--                  <label>-->
              <!--                    <input type="checkbox"> 记住十万年-->
              <!--                  </label>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--            </div>-->

              <div class="am-form-group" style="margin-top: 72px;">
                <div class="am-u-sm-10">
                  <button @click.prevent="dataFormSubmit(2)" style="background: rgb(11,126,232);color: #ffffff;
                  font-size: 40px;font-weight: 400;border: none;width: 100%;margin-left: 55px;height: 90px"
                  >登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录</button>
                </div>
              </div>
              <div class="am-form-group" style="margin-top: 54px;">
                <div class="am-u-sm-10">
<!--                  <router-link class="router" to="/index">-->
<!--                  <button style="background: rgb(11,126,232);color: #ffffff;-->
<!--                  font-size: 40px;font-weight: 400;border: none;width: 100%;margin-left: 55px;height: 90px">专家入口</button>-->
<!--                  </router-link>-->
                  <button @click.prevent="dataFormSubmit(1)" style="background: rgb(11,126,232);color: #ffffff;
                  font-size: 40px;font-weight: 400;border: none;width: 100%;margin-left: 55px;height: 90px">专家入口</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Notification
          ref="notification"
          placement="topRight"
          :duration="null"
          :top="30"
          @close="onClose" />
    </div>
  </div>
</template>

<script>
import Notification from '@/components/common/Notification.vue'

export default {
  name: "Login",
  data() {
    return {
      verifyImageUrl: '/api/verifyImage',
      user: {},
      type: '登录',
      moveMessage: '没有账号，创建账号',
      showLogin: true,
      message: '',
      showMessage: false,
      username: '',
      password: '',
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Notification
  },
  created() {

  },
  methods: {
    onClose () { // 点击默认关闭按钮时触发的回调函数
      console.log('关闭notification')
    },
    onShowNotification (description) {
      const notification = {
        title: '提示',
        description: description
      }
      // this.$refs.notification.open(notification) // 默认使用
      // this.$refs.notification.info(notification) // info调用
      // this.$refs.notification.success(notification) // success调用
      this.$refs.notification.error(notification) // error调用
      // this.$refs.notification.warn(notification) // warning调用
    },
      onShowNotificationSucceed (description) {
          const notification = {
              title: '提示',
              description: description
          }
          this.$refs.notification.success(notification)

      },
    onShowNotification2 () {
      const notification = {
        title: '提示',
        description: '请输入用户名密码！'
      }
      this.$refs.notification.warn(notification) // warning调用
    },
    // 提交表单
    dataFormSubmit (index) {
        this.$cookie.set('userNameSXY', '')
        this.$cookie.set('realnameSXY', '')
        this.$cookie.set('passwordSXY', '')
      if (index == 1) {
        this.$cookie.set('realnameSXY', '游客')
        this.onShowNotificationSucceed("登录成功")
        this.$router.replace('/index')
      }else {
        if (!this.username && !this.password) {
          this.onShowNotification2()
          return
        }
        // if (this.username === 'admin' && this.password === 'admin') {
        //  var data = this.$http.adornData({
        //               'username': this.username,
        //               'password': this.password,
        //           })
        //   this.postRequest("/sys/thirdPartyLogin" ,data).then(resp => {
        //       if (resp) {
        //           this.slideshow = resp.data.data
        //           console.log(this.slideshow)
        //       }
        //   })

        this.$http({
          url: this.$http.adornUrl('/sys/thirdPartyLogin'),
          method: 'post',
          data: this.$http.adornData({
            'username': this.username,
            'password': this.password,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$cookie.set('userNameSXY', this.username)
            this.$cookie.set('passwordSXY', this.password)
            this.$cookie.set('realnameSXY', data.realname)
            this.onShowNotificationSucceed("登录成功")
            this.$router.replace('/index')
          } else {
            this.onShowNotification(data.msg)
          }
        })
      }

        // this.$router.replace('/index')
      // }else {
      //   this.onShowNotification()
      // }
      // this.$refs['dataForm'].validate((valid) => {
      //   if (valid) {
      //     if (this.username === 'admin' && this.password === 123456) {
      //       this.$router.replace({ name: 'index' })
      //     }
      //     this.$http({
      //       url: this.$http.adornUrl('/sys/login'),
      //       method: 'post',
      //       data: this.$http.adornData({
      //         'username': this.dataForm.userName,
      //         'password': this.dataForm.password,
      //         'uuid': this.dataForm.uuid,
      //         'captcha': this.dataForm.captcha
      //       })
      //     }).then(({data}) => {
      //       if (data && data.code === 0) {
      //         this.$cookie.set('isrem', this.isremember)
      //         if (this.isremember) {
      //           this.$cookie.set('userName', this.dataForm.userName)
      //           this.$cookie.set('password', this.dataForm.password)
      //         }
      //         this.$cookie.set('token', data.token)
      //         this.$router.replace({ name: 'home' })
      //       } else {
      //         this.getCaptcha()
      //         this.$message.error(data.msg)
      //       }
      //     })
      //   }
      // })
    }
    // userLogin(value) {
    //   fetch(`/api/login`, {
    //     headers: {
    //       'Content-Type': 'application/json; charset=UTF-8',
    //       'X-XSRF-TOKEN': this.$cookies.get('XSRF-TOKEN')
    //     },
    //     method: 'POST',
    //     credentials: 'include',
    //     body: JSON.stringify(value)
    //   }).then(response => response.json())
    //     .then(json => {
    //       if (json.status === 200) {
    //         const user = json.data
    //         // 保存用户
    //         this.$store.commit('setUserInfo', user)
    //         // 跳转到首页
    //         this.$router.push('/')
    //       } else {
    //         this.message = json.message
    //         this.showMessage = true
    //       }
    //     })
    //     .catch(e => {
    //       return null
    //     })
    // },
    // register(value) {
    //   fetch(`/api/register`, {
    //     headers: {
    //       'Content-Type': 'application/json; charset=UTF-8',
    //       'X-XSRF-TOKEN': this.$cookies.get('XSRF-TOKEN')
    //     },
    //     method: 'POST',
    //     credentials: 'include',
    //     body: JSON.stringify(value)
    //   }).then(response => response.json())
    //     .then(json => {
    //       if (json.status === 200) {
    //         this.message = '注册成功，即将为你跳转到登录页面！'
    //         this.showMessage = true
    //         this.moveRegister()
    //       } else {
    //         this.message = json.message
    //         this.showMessage = true
    //       }
    //     })
    //     .catch(e => {
    //       return null
    //     })
    // },
    // moveRegister() {
    //   if (this.type === '登录') {
    //     this.type = '注册'
    //   } else {
    //     this.type = '登录'
    //   }
    //   if (this.moveMessage === '没有账号，创建账号') {
    //     this.moveMessage = '已有账号，我要登录'
    //   } else {
    //     this.moveMessage = '没有账号，创建账号'
    //   }
    //   this.showLogin = !this.showLogin
    // },
    // backHome() {
    //   this.$router.push('/')
    // }
  }
}
</script>

<style lang="scss">
.site-wrapper.site-page--login {
  //position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(38, 50, 56, .6);
  overflow: hidden;

  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url(~@/assets/images/login_bg.png);
    background-size: cover;
  }

  .site-content__wrapper {
    //position: absolute;
    //top: 0;
    //right: 0;
    //bottom: 0;
    //left: 0;
    //padding: 0;
    //margin: 0;
    //overflow-x: hidden;
    //overflow-y: auto;
    //background-color: transparent;
  }

  .site-content {
    min-height: 100%;
    padding: 30px 500px 30px 30px;
  }

  .brand-info {
    margin: 220px 100px 0 90px;
    color: #fff;
  }

  .brand-info__text {
    margin: 0 0 22px 0;
    font-size: 48px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .brand-info__intro {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.58;
    opacity: .6;
  }

  .login-main {
    position: fixed;
    display:flex;
    top: 8.4%;
    left: 2.8%;
    width: 800px;
    min-height: 100%;
  }
  .login-form {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
  }

  .login-title {
    font-size: 16px;
  }

  .login-captcha {
    overflow: hidden;

    > img {
      width: 100%;
      cursor: pointer;
    }
  }

  .login-btn-submit {
    width: 100%;
    margin-top: 38px;
  }
}

</style>
