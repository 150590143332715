import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import VueCookie from 'vue-cookie'
import {getRequest} from "@/api/api";
import {postRequest} from "@/api/api";

Vue.use(VueCookie)
Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;
Vue.prototype.$http = httpRequest // ajax请求方法

Vue.config.productionTip = false
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
