// 引入 axios
import axios from "axios";
import Vue from "vue";

const http = axios.create({
    timeout: 1000 * 30,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
    config.headers['token'] = Vue.cookie.get('token') // 请求头带上token
    return config
}, error => {
    return Promise.reject(error)
})
// let base = 'http://localhost:8087/storage-api';
// let base = 'https://www.vaxrr.cn/storage-api';
let base = 'http://123.56.215.110/storage-api';

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}
export const postRequest=(url,params)=>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data: params,
    })
}
